import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: () => import("../views/Home.vue"),
    },
    {
      path: "/gallery",
      component: () => import("../views/Gallery.vue"),
    },
    {
      path: "/galleryentry",
      component: () => import("../views/GalleryEntry.vue"),
    },
    {
      path: "/login",
      component: () => import("../views/Login.vue"),
    },
    {
      path: "/register",
      component: () => import("../views/Register.vue"),
    },
    {
      path: "/account",
      component: () => import("../views/Account.vue"),
    },
    {
      path: "/about",
      component: () => import("../views/About.vue"),
    },
  ],
});

export default router;
