import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import firebase from 'firebase';
import { VuesticPlugin } from 'vuestic-ui'
import 'vuestic-ui/dist/vuestic-ui.css'

let firebaseConfig = {
  apiKey: "AIzaSyDt0ZKyg9hBjXPHtG4bko5i2_MLKq2gtwg",
  authDomain: "beholder-maps.firebaseapp.com",
  projectId: "beholder-maps",
  storageBucket: "beholder-maps.appspot.com",
  messagingSenderId: "609674429370",
  appId: "1:609674429370:web:9ddd7953542125e0f69383",
  measurementId: "G-8H1DDFVJQ2"
}

firebase.initializeApp(firebaseConfig);

const app = createApp(App);

app.use(router);
app.use(VuesticPlugin);

app.mount('#app');
