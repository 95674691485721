<template>
  <div class="main-wrapper">
    <va-navbar color="#fff" class="header">
      <template #left>
        <img class="logo" src="./assets/homepage_logo.png" />
      </template>
      <template #center>
        <router-link class="nav-link" to="/">Home</router-link>
        <router-link class="nav-link" to="/gallery">Gallery</router-link>
        <router-link class="nav-link" to="/about">About</router-link>
        <router-link v-if="isLoggedIn" class="nav-link" to="/account">Account</router-link>
      </template>
      <template #right>
        <span v-if="isLoggedIn">
          <va-button @click="logout">Logout</va-button>
        </span>
        <span v-else>
          <va-button @click="navigateToLogin">Login / Signup</va-button>
        </span>
      </template>
    </va-navbar>

    <div class="main-content">
      <router-view />
    </div>

    <va-navbar color="#fff" class="footer">
      <template #left>
        <img class="footer-logo" src="./assets/homepage_logo.png" />
      </template>
      <template #center>
        Beholder Maps © 2022
      </template>
      <template #right>
          <router-link class="footer-link" to="/">Contact Us</router-link>
          <router-link class="footer-link" to="/">Privacy Policy</router-link>
      </template>
    </va-navbar>
  </div>
</template>

<script setup>
  import { ref } from 'vue' // used for conditional rendering
  import firebase from 'firebase'
  import { useRouter } from 'vue-router'

  const router = useRouter()
  const isLoggedIn = ref(true)

  // runs after firebase is initialized
  firebase.auth().onAuthStateChanged(function(user) {
    if (user) {
      isLoggedIn.value = true // if we have a user
    } else {
      isLoggedIn.value = false // if we do not
    }
  })

  const logout = () => {
    firebase.auth().signOut()
    router.push('/')
  }

  const navigateToLogin = () => {
    router.push('/login')
  }
</script>

<style>
html, body {
  height: 100%;
  background-color: #E5E5E5;
}
#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000;
  height: 100%;
}
</style>

<style lang="scss" scoped>
.main-wrapper {
  box-sizing: border-box;
  min-height: 100%;
  position: relative;
  padding-bottom: 70px;
}

.header {
  box-shadow: 0px 1px 6px 0px #00000033;
  padding: 0 13%;
  .logo {
    height: 50px;
    
  }
  .nav-link {
    font-weight: bold;
    font-size: 16px;
    color: #000;
    padding: 0 20px;
  }
}

.main-content {
  height: 100%;
  padding: 0 13%;
  margin-top: 26px;
}

.footer {
  box-shadow: 0px 1px 6px 0px #00000033;
  padding: 0 13%;
  position: absolute;
  bottom: 0;
  width: 100%;
  .footer-logo {
    filter: grayscale(100%) brightness(1.7);
    height: 50px;
  }
  .footer-link {
    color: #4e7ae7;
    padding: 0 5px;
  }
}
</style>
